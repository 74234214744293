import { HTMLProps, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
	useDeleteUserPicture,
	useGetVerificationsCompleted,
} from '@/components/api/swrQueries.tsx';
import PhotoVerificationGroup from '@/components/custom/PhotoVerificationGroup.tsx';
import NoDataText from '@/components/custom/NoDataText.tsx';
import Loader from '@/components/custom/Loader.tsx';
import dayjs from 'dayjs';
import {
	Pagination,
	PaginationContent,
	PaginationItem,
	PaginationNext,
	PaginationPrevious,
} from '@/components/ui/pagination.tsx';
import { ISearchVerifCompletedParams, IUserPicture } from '@/lib/interface.ts';
import { confirm } from '@/components/custom/react-confirm/Confirm.tsx';
import { getFullDateStripped } from '../lib/utils';

interface Interface {
	className?: HTMLProps<HTMLElement>['className'];
}

export default function VerificationsHistoryPage({ className }: Interface) {
	const [searchParams, setSearchParams] = useSearchParams();
	const searchDate = searchParams.get('date') as string;
	const searchPage = searchParams.get('page') as string;
	const searchTerm: ISearchVerifCompletedParams = {
		page: searchPage ? parseInt(searchPage) : 1,
		date: dayjs(searchDate).isValid() ? searchDate : dayjs().format('YYYY-MM-DD'),
	};
	const [page, setPage] = useState<number>(searchTerm.page);
	const { trigger: deleteUserPicture } = useDeleteUserPicture();
	const { data: verifs, isLoading: isLoadingVerifs } = useGetVerificationsCompleted({
		page,
		date: dayjs(searchTerm.date).toISOString(),
	});
	const [verifications, setVerifications] = useState(verifs?.data || []);

	useEffect(() => {
		if (!verifs) return;
		setVerifications(verifs.data);
	}, [verifs]);

	useEffect(() => {
		if (!page) return;
		updateSearchParam('page', page.toString());
	}, [page]);

	const updateSearchParam = (key: string, value: string) => {
		const params = new URLSearchParams(searchParams);
		params.set(key, value);
		setSearchParams(params, { replace: true });
	};

	const onClickDeletePictureBtn = async (userId: string, picture: IUserPicture) => {
		const yes = await confirm(
			'Etes-vous sur de vouloir supprimer cette photo du profil de cet utilisateur?',
		);
		if (!yes) return;
		setVerifications(prevState => {
			return prevState.map(item => {
				const userPics = item.userData.pictures;
				item.userData.pictures = userPics.filter(pic => pic.path !== picture.path);
				return item;
			});
		});
		/* Make api request without waiting res */
		await deleteUserPicture({ userId, position: picture.position });
	};

	return (
		<div className={`md:container mb-4 gap-y-4 flex flex-col items-center ${className}`}>
			<NoDataText
				text={`Vérifications effectuées le ${getFullDateStripped(
					new Date(searchTerm.date),
				)}`}
			/>
			{isLoadingVerifs ? (
				<Loader text={'Chargement des verifications effectuées...'} />
			) : (
				<div className={'flex flex-col gap-y-4'}>
					{verifications.length > 0 ? (
						verifications.map((item, index) => {
							return (
								<PhotoVerificationGroup
									readonly
									key={index}
									item={item}
									onClickDeletePictureBtn={onClickDeletePictureBtn}
								/>
							);
						})
					) : (
						<NoDataText text={'Aucune verification pour cette date'} />
					)}
				</div>
			)}
			{/* Pagination */}
			<Pagination>
				<PaginationContent>
					{verifs && verifs.data?.length > 0 && verifs?.nextPage && (
						<PaginationItem>
							<PaginationPrevious
								onClick={() => setPage(prev => Math.max(prev - 1, 0))}
							/>
						</PaginationItem>
					)}
					{verifs?.nextPage && (
						<PaginationItem>
							<PaginationNext onClick={() => setPage(verifs?.nextPage)} />
						</PaginationItem>
					)}
				</PaginationContent>
			</Pagination>
		</div>
	);
}
