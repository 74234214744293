import { Route, Routes } from 'react-router-dom';
import LoginPage from '@/pages/LoginPage.tsx';
import routes from '@/lib/routes.tsx';
import HomePage from '@/pages/HomePage.tsx';
import Navbar from '@/components/custom/Navbar.tsx';
import userStore from '@/store/userStore.ts';
import PrivateRoute from '@/components/custom/PrivateRoute.tsx';
import PageNotFound from '@/pages/PageNotFound.tsx';
import { apiClient } from '@/components/api/apiClient.ts';
import CertificationsPage from '@/pages/CertificationsPage.tsx';
import ProfileDetailsPage from '@/pages/ProfileDetailsPage.tsx';
import VerificationsPage from '@/pages/VerificationsPage.tsx';
import BioReviewPage from '@/pages/BioReviewPage.tsx';
import ReportsPage from '@/pages/ReportsPage.tsx';
import FindUserPage from '@/pages/FindUserPage.tsx';
import ReferralPage from '@/pages/ReferralPage.tsx';
import SubAccountsPage from '@/pages/SubAccountsPage.tsx';
import VerificationsHistoryPage from '@/pages/VerificationsHistoryPage.tsx';

function App() {
	const user = userStore(state => state.user);

	if (user) {
		/* Makes sure the token is set */
		apiClient.setHeader('Authorization', `Bearer ${user.token}`);
	}

	return (
		<div>
			{user ? <Navbar /> : null}
			<main>
				<Routes>
					{/* Private routes */}
					<Route path={routes.nav.home} element={<PrivateRoute page={<HomePage />} />} />
					<Route
						path={routes.nav.certifications}
						element={<PrivateRoute page={<CertificationsPage />} />}
					/>
					<Route
						path={routes.nav.verifications}
						element={<PrivateRoute page={<VerificationsPage />} />}
					/>
					<Route
						path={routes.nav.referral}
						element={<PrivateRoute page={<ReferralPage />} />}
					/>
					<Route
						path={routes.nav.bioReview}
						element={<PrivateRoute page={<BioReviewPage />} />}
					/>
					<Route
						path={routes.nav.reports}
						element={<PrivateRoute page={<ReportsPage />} />}
					/>
					<Route
						path={routes.nav.findUser}
						element={<PrivateRoute page={<FindUserPage />} />}
					/>
					<Route
						path={routes.nav.verificationsHistory}
						element={<PrivateRoute page={<VerificationsHistoryPage />} />}
					/>
					<Route
						path={`${routes.nav.profileDetails}/:id`}
						element={<PrivateRoute page={<ProfileDetailsPage />} />}
					/>
					<Route
						path={`${routes.nav.profileDetails}/:id/${routes.nav.subAccounts}`}
						element={<PrivateRoute page={<SubAccountsPage />} />}
					/>
					{/* Public routes */}
					<Route path={routes.nav.login} element={<LoginPage />} />
					<Route path="*" element={<PageNotFound />} />
				</Routes>
			</main>
		</div>
	);
}

export default App;
